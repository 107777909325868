import { ChevronLeft, ChevronRight } from "lucide-react";
import CustomArrow from "~/components/CustomArrow";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Review from "~/types/Review";
import { useLoaderData } from "@remix-run/react";
import ImgWithDashedBorder from "~/components/ImgWithDashedBorder";
import { cn, localeAttr } from "~/utils/helper";
import useHost from "~/utils/hooks/useHost";
import { useTranslation } from "react-i18next";
import ReviewLabel from "~/components/ReviewLabels";

export default function ReviewCard({
  review,
  className,
  locale,
}: {
  review: Review;
  className?: string;
  locale: string;
}) {
  const swiperSlide = useSwiperSlide();
  const active = swiperSlide?.isNext;
  const HOST = useHost();

  return (
    <article
      className={cn(
        "relative mt-16 flex min-h-64 min-w-[calc(100%-20px)] flex-col items-center rounded-xl bg-gray-50 px-4 pb-6 pt-14 transition-all duration-500 max-lg:px-1 max-lg:pb-2 lg:flex lg:min-w-[calc(25%-20px)]",
        {
          " min-h-[356px] min-w-[calc(100%-20px)] bg-Primary-Magic-Mint-Mm-50 pt-20  lg:min-w-[calc(50%-20px)] ":
            active,
        },
        className,
      )}
    >
      <ImgWithDashedBorder
        src={`${HOST}${review.attributes.picture?.data.attributes.url}`}
        alt={localeAttr(review.attributes, "name", locale)}
        className={cn("absolute -top-16 left-1/2 size-28  -translate-x-1/2", {
          "size-32": active,
        })}
      />
      <h4
        className={cn(
          " text-center text-lg font-semibold text-Secondary-Dark-Blue-db-500",
          { "text-xl": active },
        )}
      >
        {localeAttr(review.attributes, "name", locale)}
      </h4>
      <p
        className={cn(
          " text-center text-sm font-normal text-Secondary-Crayola-Space-Cs-200",
          {
            "text-base": active,
          },
        )}
      >
        {localeAttr(review.attributes, "job_title", locale)}
      </p>
      <ReviewLabel label={review.attributes.label} className="mt-2" />
      <div
        className={cn(
          `relative mx-5 mt-5 text-justify  text-Secondary-Crayola-Space-Cs-600 max-md:mt-1 `,
          {
            "text-base": active,
          },
        )}
        dir={locale === "ar" ? "rtl" : "ltr"}
      >
        <p
          className={`${!active ? "line-clamp-5" : "line-clamp-6 max-md:line-clamp-5"}`}
          title={localeAttr(review.attributes, "review", locale)}
        >
          {localeAttr(review.attributes, "review", locale)}
        </p>
      </div>
    </article>
  );
}
